<template>
  <div id="tidio-chat"></div>
</template>

<script>
export default {
  name: 'TidioChat',
  mounted() {
    const injectScript = document.createElement('script');
    injectScript.src = '//code.tidio.co/0r4s1aqwlru5fhgvuhxdn4apgodutedy.js';
    injectScript.async = true;
    document.head.appendChild(injectScript);

    injectScript.onerror = () => {
      console.error('Error loading Tidio script');
    };
  },
};
</script>