<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" />
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="{ active: menuVisible }">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="header-container section">
          <Header :cartContents="cartContents" @openSaleSkinModal="openSaleSkinModal" @logout="logout" v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')" />
        </div>
        <router-view @paymentSuccess="checkForDiscount" @openOrderModal="openOrderModal"
          @openOfferDetailsModal="openOfferDetailsModal" @formatDate="formatDate"
          :addToCartChosenItem="addToCartChosenItem" :currencySymbol="currencySymbol"
          @addToCart="addToCart" @goToProduct="goToProduct" @login="login" @forgotPass="forgotPass"
          @registration="registration" />
        <div class="footer-container">
          <Footer v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')" />
        </div>
        <transition name="fade">
          <OfferDetailsModal v-if="offerDetailsModalIsVisible" @closeOfferDetailsModal="closeOfferDetailsModal" :orderToView="orderToView" />
        </transition>
        <transition name="fade">
          <Login
              v-if="signInModalIsVisible"
              :is-verified="isVerified"
              @resendConfirmation="resendConfirmationLink"
              @closeSignInModal="closeSignInModal"
              @openSignUpModal="openSignUpModal"
              @login="login"
              @forgotPass="forgotPass"
          />
        </transition>
        <transition name="fade">
          <Registration v-if="signUpModalIsVisible" @closeSignUpModal="closeSignUpModal" @openSignInModal="openSignInModal" @registration="registration" />
        </transition>
        <transition name="fade">
          <SaleSkinModal v-if="saleSkinModalIsVisible" @closeSaleSkinModal="closeSaleSkinModal" />
        </transition>
        <transition name="fade">
          <OrderModal :appDomain="appDomain" :orderData="orderData" v-show="orderModalIsVisible" :orderModalIsVisible="orderModalIsVisible" @orderSubmit="orderSubmit"/>
        </transition>

        <Transition>
          <DiscountModal 
          ref='discountModal'
          v-show="discountModalVisible"
          :is-auth="isAuth"
          :currency-code="currency"
          :currency-symbol="currencySymbol"
          :addToCartChosenItem="addToCartChosenItem" 
          @addToCart="addToCart"
          @goToProduct="goToProduct"
          @closeDiscountModal="closeDiscountModal"
          @openDiscountModal="openDiscountModal"
          />
        </Transition>

        <div class="modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="desc">
    {{
      $te('This Website stores cookies on your computer')
        ? $t('This Website stores cookies on your computer')
        : 'This Website stores cookies on your computer. These cookies collect information about how you navigate our Website and allow us to remember you. We use this information to improve and customise your browsing experience and for analytics and metrics about our visitors on this Website.'
    }}
    <br />
    {{
      $te('Before you proceed with using our Website')
        ? $t('Before you proceed with using our Website')
        : 'Before you proceed with using our Website, please get acquainted with the way we handle a User’s data in our Privacy Policy and the use of Cookies in the Cookies Policy.'
    }}
    <br />
    {{
      $te('You can either accept or decline use of Cookies')
        ? $t('You can either accept or decline use of Cookies')
        : 'You can either accept or decline use of Cookies. If you opt to decline Cookies your information won’t be tracked when you visit this Website.'
    }}
  </div>
            <button class="button" @click="acceptCookies">
              {{ $t("Accept") }}
            </button>
            <button class="button" @click="declineCookies">
              {{ $t("Decline") }}
            </button>
          </div>
        </div>
      </div>
    </transition>

    <BotPressChat/>
  </div>
</template>


<script>
import Header from "./components/Header.vue";
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";
import OfferDetailsModal from "./components/OfferDetailsModal.vue";
import SaleSkinModal from "./components/SaleSkinModal.vue";
import Login from "./components/Login.vue";
import Registration from "./components/Registration.vue";
import { loadScriptsFromString } from "@/utils/load_scripts";
import { mapActions, mapState, mapGetters } from "vuex";
import OrderModal from './components/OrderModal.vue'
import DiscountModal from "./components/DiscountModal.vue";
import BotPressChat from "./components/BotPressChat.vue";

export default {
  name: "app",
  components: {
    Header,
    Menu,
    Footer,
    OfferDetailsModal,
    SaleSkinModal,
    Registration,
    Login,
    OrderModal,
    DiscountModal,
    BotPressChat
  },
  data: function () {
    return {
      pageLoaded: false,
      menuVisible: false,
      offerDetailsModalIsVisible: false,
      saleSkinModalIsVisible: false,
      nav: [
        {
          title: this.$t("Home"),
          link: "/home",
        },
        {
          title: this.$t("Shop RUST"),
          link: "/products/rust",
        },
        {
          title: this.$t("Shop Dota 2"),
          link: "/products/dota2",
        }
      ],
      isAuth: false,
      error: "",
      successMessage: "",
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: "",
      newArrivals: [],
      recommendedProducts: [],
      saleProducts: [],
      cartContents: [],
      totalSum: "0.00",
      textPageTitle: "",
      textPageHeader: "",
      textPageContent: "",
      productPageItem: "",
      similarProducts: "",
      imgDomain: "",
      textPageList: "",
      hostname: "",
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      footerRequisites: "",
      footerSupportEmail: "",
      footerCopyright: "",
      cookesModalIsVisible: false,
      socialLinks: [],
      isVerified: true,
      orderModalIsVisible: false,
      orderData: {},
      minAmount: 0,
      paymentMethods: [],
      appDomain: '',
      errorTimeout: null,
      discountModalVisible: false,
    };
  },
  watch: {
    isAuth() {
      if (this.isAuth) {
        this.fetchActiveDiscount(this.currencyCode);
      }
    },
    currency() {
      if (this.productPageItem && this.productPageItem.id) {
        this.getProductPageContent(this.productPageItem.id);
      }
      if (this.isAuth) {
        this.orderHistory = '';
        this.cartContents = '';
        this.totalSum = '';
        this.getOrderHistory();
        this.getCartContents();
      }
    },
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
      }
    },
  },
  methods: {
    ...mapActions("app", ["fetchCurrencies", "fetchLanguages"]),
    ...mapActions('discount', ['fetchActiveDiscount']),

    checkForDiscount() {
      if (this.isAuth) {
        this.getCartContents();
        this.$refs.discountModal.fetchDiscountData();
      }
    },
    openDiscountModal() {
      this.discountModalVisible = true;
    },
    closeDiscountModal() {
      this.discountModalVisible = false;
    },
    orderSubmit(data, type) {
      data.paymentType = type;
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
        if (res.data.status == 'ERROR') {
          this.$router.push({ name: "PaymentFaliPage" });
          this.orderModalIsVisible = false;
        } else {
          window.location = res.data.redirect_url;
        }
      })
      .catch((res) => {
        this.error = res.response.data.message;
      })
    },
    openOrderModal(data) {
      if (this.totalSum < this.minAmount) {
        this.error =  'Sorry, but minimum order amount is ' +  this.minAmount + ' ' + this.currency;
        this.showAndHideError(); 
      } else {
        this.error = '';
        this.orderModalIsVisible = true;
        this.orderData = data;
      }
    },
    showAndHideError() { 
      clearTimeout(this.errorTimeout); 
      this.errorTimeout = setTimeout(() => { 
        this.errorTimeout = null; 
        this.error = ''; 
      }, 3000); 
    },
    closeOrderModal() {
      this.orderModalIsVisible = false;
      this.error = '';
    },
    getGoogleTagManagerSnippet() {
      this.$http
        .get(process.env.VUE_APP_API + "settings")
        .then((res) => {
          this.paymentMethods = res.data.payload.payment_methods;
          this.minAmount = res.data.payload.min_top_up;
          this.footerRequisites = res.data.payload.requisites;
          this.footerSupportEmail = res.data.payload.support_email;
          this.footerCopyright = res.data.payload.copyright;
          const snippet = res.data.payload.google_tag;

          if (snippet) {
            loadScriptsFromString(snippet);
          }
        })
        .catch((error) => {
          console.error("Error fetching Google Tag Manager snippet:", error);
        });
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    clearError() {
      this.error = "";
    },
    openOfferDetailsModal(item) {
      this.orderToView = item;
      this.offerDetailsModalIsVisible = true;
    },
    closeOfferDetailsModal() {
      this.offerDetailsModalIsVisible = false;
      this.orderToView = {};
    },
    openSaleSkinModal() {
      this.saleSkinModalIsVisible = true;
    },
    closeSaleSkinModal() {
      this.saleSkinModalIsVisible = false;
    },
    login(data) {
      this.error = '';
      this.isVerified = true;
      this.$http
          .post(process.env.VUE_APP_API + "login", data)
          .then((res) => {
            if (res.data.status == "OK") {
              this.isAuth = true;
              this.getOrderHistory();
              this.getCartContents();
              this.closeSignInModal();
              this.$router.push({ path: "/profile" });
            } else {
              this.error = res.data.message;
            }
          })
          .catch((res) => {
            this.error = res.response.data.message;
            if (res.response.data.status === "VERIFY") {
              this.isVerified = false;
            }
          });
    },
    resendConfirmationLink(data) {
      this.error = '';
      this.$http
          .post(process.env.VUE_APP_API + "resendConfirmationLink", {email: data})
          .then((res) => {
            if (res.data.status == "OK") {
              this.successMessage = this.$t("Confirmation link has been resent.");
            } else {
              this.error = res.data.message;
            }
          })
          .catch((res) => {
            this.error = res.response.data.message;
          });
    },
    forgotPass(data) {
      let self = this;
      this.$http
        .post(process.env.VUE_APP_API + "user/reset-password", data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.successMessage = res.data.message;
            this.error = "";
            setTimeout(() => {
              self.successMessage = "";
            }, 2000);
          } else {
            this.successMessage = "";
            this.error = res.response.data.message;
          }
        })
        .catch((error) => {
          this.successMessage = "";
          this.error = error.response.data.message;
        });
    },
    registration(data) {
      this.clearError();
      this.isVerified = true;
      let self = this;
      this.$http.post(process.env.VUE_APP_API + "register", data)
          .then((res) => {
            if (res.status === 200 && res.data.status === "OK") {
              this.clearError();
              self.isAuth = true;
              this.closeSignUpModal();
              self.$router.push({path: "/profile"});
            } else if (res.status === 202 && res.data.status === "VERIFY") {
              this.error = "Your registration is being processed. Please check your email for a verification link.";
            } else {
              this.error = res.data.message;
            }
          })
          .catch((error) => {
            if (error.response.status >= 400 && error.response.status < 500) {
              this.error = error.response.data.message;
            } else {
              this.error = "An error occurred. Please try again later.";
            }
          });
    },
    logout() {
      this.$http
        .post(process.env.VUE_APP_API + "logout")
        .then(() => {
          if (this.$route.name != "Home") {
            this.$router.push({ path: "/" });
          }
          this.isAuth = false;
          this.$router.push({ path: "/" });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            console.log(res);
          }
        });
    },
    getOrderHistory() {
      this.$http
        .get(process.env.VUE_APP_API + "orders/history")
        .then((res) => {
          this.orderHistory = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    formatDate(num) {
      let objectDate = new Date(num);
      let day = objectDate.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, "0");
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ":" + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + " " + hoursAndMinutes;

      return format;
    },
    removeOneFromCart(item, id, item_type) {
      let self = this;

      let cartItem = {
        id: id,
        count: 1,
        item_type: item_type
      };

      if (item.count > 1) {
        cartItem = {
          ...cartItem,
          count: item.count - 1,
        };
      } else {
        this.removeFromCart(item, id, item_type);
        return;
      }

      this.$http
        .post(process.env.VUE_APP_API + "cart/add", cartItem)
        .then((res) => {
          self.cartContents = res.data.cart;
          this.getCartContents();
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },

    removeFromCart(item, id, item_type) {
      let self = this;
      
      let cartItem = {
        id: id,
        item_type: item_type
      };

      this.$http
        .post(process.env.VUE_APP_API + "cart/remove", cartItem)
        .then((res) => {
          self.cartContents = res.data.cart;
          this.getCartContents();
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    addToCart(item, id, item_type) {

      if (!this.isAuth) {
        this.openSignInModal();
      } else {
        let self = this;
        let cartItem = {
          id: id,
          count: 1,
          item_type: item_type
        };

        for (var i = 0; i < this.cartContents.length; i++) {
          if (this.cartContents[i].item_id == cartItem.id && this.cartContents[i].item.item_type === cartItem.item_type) {
            let cartItemCount = this.cartContents[i].count;
            cartItem = {
              ...cartItem,
              count: cartItemCount + 1,
            };
            break;
          }
        }

        this.$http
          .post(process.env.VUE_APP_API + "cart/add", cartItem)
          .then((res) => {
            this.getCartContents();
            self.addToCartChosenItem = cartItem.id;
            self.cartContents = res.data.cart;
            setTimeout(() => {
              self.addToCartChosenItem = "";
            }, 2000);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.openSignInModal();
            }
          });
      }
    },
    getNewArrivals() {
      this.$http
        .get(process.env.VUE_APP_API + "items/list?new=true")
        .then((res) => {
          this.newArrivals = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getRecommended() {
      this.$http
        .get(process.env.VUE_APP_API + "items/recommended")
        .then((res) => {
          this.recommendedProducts = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getSale() {
      this.$http
        .get(process.env.VUE_APP_API + "items/list?sale=true")
        .then((res) => {
          this.saleProducts = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getCartContents() {
      this.$http
        .get(process.env.VUE_APP_API + "cart/contents")
        .then((res) => {
          this.cartContents = res.data.cart;

          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice = this.cartContents[i].count * parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    goToProduct(item) {
      this.$router.push({
        path: "/product/" + item.id,
        params: { data: item.id },
      });
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = "";
      this.similarProducts = "";
      this.$http
        .get(process.env.VUE_APP_API + "items/" + id + "?include_price_history=true")
        .then((res) => {
          this.productPageItem = res.data.payload;
          this.similarProducts = res.data.payload.similar;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    goToPage(item) {
      let newItem;
      if (item == "privacy") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("privacy")) {
            newItem = this.textPageList[i].id;
          }
        }
      } else if (item == "terms") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes("terms")) {
            newItem = this.textPageList[i].id;
          }
        }
      } else {
        newItem = item;
      }
      this.$router.push({
        path: "/pages/" + newItem,
        params: { data: newItem },
      });
      this.getTextPageContent(newItem);
    },
    getTextPageContent(id) {
      this.textPageTitle = "";
      this.textPageHeader = "";
      this.textPageContent = "";
      this.$http
        .get(process.env.VUE_APP_API + "static-pages/" + id)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    getTextPages() {
      this.$http
        .get(process.env.VUE_APP_API + "static-pages")
        .then((res) => {
          this.textPageList = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.openSignInModal();
          }
        });
    },
    closeSignUpModal() {
      this.signUpModalIsVisible = false;
      this.clearError();
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openSignInModal() {
      this.clearError();
      let self = this;
      this.signUpModalIsVisible = false;
      setTimeout(() => {
        self.signInModalIsVisible = true;
      }, 300);
    },
    openSignUpModal() {
      this.clearError();
      let self = this;
      this.signInModalIsVisible = false;
      setTimeout(() => {
        self.signUpModalIsVisible = true;
      }, 300);
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem("cookiesAccepted");

      if (!cookiesAccepted) {
        this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem("cookiesAccepted", "true");
      this.cookesModalIsVisible = false;
    },
  },
  mounted: async function () {
    this.cookiesModal();
    await this.fetchLanguages();
    await this.fetchCurrencies();
    this.hostname = this.getDomainName(window.location.hostname);
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.appDomain = process.env.VUE_APP_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();

    self.$http
      .get(process.env.VUE_APP_API + "is-auth")
      .then((res) => {
        self.isAuth = res.data.authenticated;

        if (res.data.authenticated) {
          self.getOrderHistory();
          self.getCartContents();
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.openSignInModal();
        }
      });
    if (this.isAuth) {
      this.fetchActiveDiscount(this.currency);
    }
    self.$http
      .get(process.env.VUE_APP_API + "social-links")
      .then((res) => {
        const data = res.data;

        if (data.status === "OK") {
          self.socialLinks = data.payload || [];
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.openSignInModal();
        }
      });

    self.getGoogleTagManagerSnippet();
    this.getSale();
    this.getRecommended();
    this.getNewArrivals();
    this.getTextPages();
  },
  computed: {
    ...mapState({
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    ...mapGetters("app", ["currency", "currencySymbol"]),
  },
};
</script>
