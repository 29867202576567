<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/logo.svg" class="img" />
          <img src="./../assets/logo-mini.svg" class="img mob" />
        </router-link>
      </div>

      <CurrencyDropdown />

      <ul class="nav">
        <template v-for="(item, i) in $parent.nav">
          <li class="nav__item" :key="i">
            <router-link :to="item.link">{{ $t(item.title) }}</router-link>
          </li>
        </template>
      </ul>
      <div class="right">
        <Dropdown />
        <router-link to="/cart" v-if="$parent.isAuth" class="icon">
          <img src="./../assets/cart.svg" class="img" />
          <div v-if="cartContents.length" class="indicator">
            {{ cartContents.length }}
          </div>
        </router-link>
        <router-link to="/profile" class="button link-container dropdown-open" v-if="$parent.isAuth">
          <img src="./../assets/user.svg" class="img" />
        </router-link>
        <div class="link-container" v-else>
          <div class="button nav__item-signIn" @click="$parent.openSignInModal">
            <a>{{ $t("Sign in") }}</a>
          </div>
          <div class="button modal-open nav__item-signUp" @click="$parent.openSignUpModal">
            <span>{{ $t("Sign up") }}</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import Dropdown from "./Dropdown.vue"
import CurrencyDropdown from "./CurrencyDropdown.vue"
export default {
  name: "Header",
  props: ["cartContents"],
  components: {
    CurrencyDropdown,
    Dropdown
  },
  data: function () {
    return {
      curr: "",
    };
  },
  computed: {
    ...mapState("app", ["currencies", "currency"]),
    ...mapState({
      languages: ({ app: { languages } }) => languages.map(({ id, title: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    formattedCurrencies() {
      return this.currencies.map(currency => ({
        id: currency.id,
        text: `${currency.text} (${currency.symbol})`,
      }));
    },
    ...mapGetters("app", ["currentLanguageName"]),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      },
    },
  },
  watch: {
    currentLanguage() {
      if (this.$route.name === "TextPage") {
        this.$router.push({ path: "/" });
      }
    },
  },
  methods: {
    ...mapMutations("app", ["setCurrentLanguage", "setCurrency", "changeLocale"]),
    selectCurrency(selectedOption) {
      this.setCurrency(selectedOption);
    },
    selectLanguage(event) {
      this.changeLocale(parseInt(event));
    },
    openSignInModal() {
      this.$emit("openSignInModal");
    },
    openSaleSkinModal() {
      this.$emit("openSaleSkinModal");
    },
    logout() {
      this.$emit("logout");
    },
  },
};
</script>
