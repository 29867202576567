// store/modules/country.js

import axios from "axios";
import { getSupportedCountryCodes } from "@/utils/phoneUtils";

const state = {
//   country: null,
  countryOptions: [],
  countryOptionsIso: [],
};

const getters = {
//   country: (state) => state.country,
  countryOptions: (state) => state.countryOptions,
  countryOptionsIso: (state) => state.countryOptionsIso,
};

const mutations = {
  SET_COUNTRY_OPTIONS(state, options) {
    state.countryOptions = options;
  },
  SET_COUNTRY_OPTIONS_ISO(state, optionsIso) {
    state.countryOptionsIso = optionsIso;
  },
};

const actions = {
  getCountries({ commit }) {
    return new Promise(async (resolve, reject) => {
      const supportedIsoCodes = getSupportedCountryCodes();
      try {
        const response = await axios.get(process.env.VUE_APP_API + "countries");
        if (response.data && response.data.payload) {
          const countryOptions = response.data.payload.filter((country) =>
            supportedIsoCodes.includes(country.iso)
          );
          const countryOptionsIso = countryOptions.map(
            (country) => country.iso
          );
          commit("SET_COUNTRY_OPTIONS", countryOptions);
          commit("SET_COUNTRY_OPTIONS_ISO", countryOptionsIso);
          resolve();
        } else {
          commit("SET_COUNTRY_OPTIONS", []);
          commit("SET_COUNTRY_OPTIONS_ISO", []);
          resolve();
        }
      } catch (error) {
        if (error.response.status === 401) {
          reject({ status: 401 });
        } else {
          reject(error);
        }
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
