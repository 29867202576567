<template>
	<div class="item-wrapper">
		<div
			v-if="tag"
			class="tag"
		>
			<span>{{ tag }}</span>
		</div>

		<div
			v-if="hasActiveDiscount && !lastSale"
			class="discount-badge"
		>
			<img
				src="@/assets/discount-icon.svg"
				alt="Discount"
				class="discount-icon"
			>
		</div>

		<div class="preview">
			<img
				v-if="item.category == 'Cases'"
				@click="goToSliderItem(item, item.category)"
				:src="item.img_url"
				class="img"
			/>
			<img
				v-else
				@click="goToSliderItem(item, item.category)"
				:src="imgDomain + item.img_url"
				class="img"
			/>
			<transition name="fade">
				<span
					class="title"
					v-if="addToCartChosenItem == item.id"
				>{{ $t(`Added`) }}</span>
			</transition>
		</div>

		<a
			@click="goToSliderItem(item, item.category)"
			class="desc"
		>{{ getTitle(item) }}</a>

		<div class="item-details">
			<template v-if="item.category && item.category.toLowerCase() == 'rust'">
				<div
					class="hero"
					v-if="item.hero"
				>Hero: {{ item.hero }}</div>
				<div
					class="hero"
					v-if="item.class"
				>Class: {{ item.class }}</div>
			</template>

			<template v-else-if="item.category && item.category.toLowerCase() == 'cases'">
				<div class="hero">Game: Rust</div>
				<div class="hero">Type: Pack</div>
			</template>

			<template v-else>
				<div class="rarity">
					<span class="rarity-label">Rarity: </span>
					<span :style="{ color: rarityColor }">{{ item.quality }}</span>
				</div>
				<div class="hero">Hero: {{ item.hero }}</div>
			</template>

		</div>

		<div class="price-wrapper">
			<span
				class="discount"
				v-if="item.old_price && item.old_price != '0.00'"
			>
				<span class="old-price">
					{{ item.old_price }} {{ currencySymbol }}
				</span>
			</span>
			<span class="price-button">
				{{ item.price }} {{ currencySymbol }}
			</span>
		</div>
	</div>
</template>


<script>
import { mapGetters } from 'vuex'


export default {
	name: 'ProductCard',
	props: ['item', 'addToCartChosenItem', 'sliderIsClickble', 'tag', 'lastSale'],
	data: function () {
		return {
			imgDomain: '',
			appDomain: '',
		}
	},
	computed: {
		...mapGetters('discount', ['hasActiveDiscount']),
		...mapGetters("app", ["currencySymbol"]),
		rarityColor() {
			const rarityColors = {
				common: '#B0C3D9',
				rare: '#4B69FF',
				mythical: '#8847FF',
				uncommon: '#5E98D9',
				immortal: '#E4AE39',
				legendary: '#D32CE6',
				arcana: '#ADE55C',
				seasonal: '#FFF34F',
				ancient: '#EB4B4B',
			};
			return rarityColors[this.item?.quality?.toLowerCase()] || '#FFFFFF';
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		getTitle(item) {
			return item.title || item.name;
		},
		goToSliderItem(item, category) {
			if (category == 'Cases') {
				this.$router.push({ path: `/packs/${item.slug}` });
				return;
			}
			else {
				this.$router.push({ path: `/product/${item.id}` });
			}
			let self = this;

			setTimeout(() => {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
	},
}
</script>


<style scoped lang="scss">
.item-wrapper {
	position: relative;

	.discount-badge {
		position: absolute;
		top: 4px;
		right: 10px;
		display: flex;
		align-items: center;
		border-radius: 12px;
		z-index: 1;
	}

	.discount-icon {
		width: 24px;
		height: 24px;
		pointer-events: none;
	}
}

.item-details {
	height: 40px;
}
</style>
