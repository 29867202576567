import axios from "@/axios";
import { debounce } from "lodash";

export default {
  namespaced: true,
  state: {
    activeDiscount: null,
    discountDuration: 0,
    discountPercentage: 0,
  },
  mutations: {
    SET_ACTIVE_DISCOUNT(state, discount) {
      state.activeDiscount = discount;
    },
    SET_DISCOUNT_DURATION(state, duration) {
      state.discountDuration = duration;
    },
    SET_DISCOUNT_PERCENTAGE(state, percentage) {
      state.discountPercentage = percentage;
    },
  },
  actions: {
    async fetchDiscountData({ commit }, currencyCode) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}post-purchase-discount?currency=${currencyCode}`
        );
        const data = response.data;
        if (data.status === "ERROR") {
          commit("SET_ACTIVE_DISCOUNT", null);
        } else {
          commit("SET_ACTIVE_DISCOUNT", {
            discountDuration: data.discountDuration,
            discountPercentage: data.discountPercentage,
          });
          commit("SET_DISCOUNT_DURATION", data.discountDuration);
          commit("SET_DISCOUNT_PERCENTAGE", data.discountPercentage);
        }
      } catch (error) {
        console.error("Error fetching discount data:", error);
        commit("SET_ACTIVE_DISCOUNT", null);
      }
    },

    fetchActiveDiscount: debounce(async function ({ dispatch }, currencyCode) {
      await dispatch("fetchDiscountData", currencyCode);
    }, 350),
  },
  getters: {
    hasActiveDiscount: (state) => !!state.activeDiscount,
    getDiscountPercentage: (state) => state.discountPercentage,
    getDiscountDuration: (state) => state.discountDuration,
  },
};
