<template>
  <div class="menu">
    <ul class="nav">
      <template v-for="(item, i) in $parent.nav">
        <li class="nav__item" :key="i">
          <router-link :to="item.link">{{ $t(item.title) }}</router-link>
        </li>
      </template>
      <!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">{{ $t(`Sale your skin`) }}</a>
			</div> -->
    </ul>
    <div class="dropdown-list">
      <CurrencyDropdown />
      <Dropdown />
    </div>
  </div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Dropdown from "./Dropdown.vue";
import CurrencyDropdown from "./CurrencyDropdown.vue";
export default {
  name: "Menu",
  props: [],
  components: {
    Dropdown,
    CurrencyDropdown
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState({
      languages: ({ app: { languages } }) => languages.map(({ id, title: text }) => ({ id, text })),
      currentLanguage: (state) => state.app.currentLanguage,
    }),
    ...mapGetters("app", ["currentLanguageName"]),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      },
    },
  },
  methods: {
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    ...mapMutations("app", ["setCurrentLanguage"]),
    ...mapActions("app", ["changeLocale"]),
  },
};
</script>

<style scoped lang="scss">
.dropdown-list {

  .menu .select-container{
    margin-top: 32px;
  }
  display: flex;
  flex-direction: column;
  // gap: 32px;
}
</style>