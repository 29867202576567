<template>
    <div class="modal discount-modal">
        <div class="overlay" @click="handleCloseModal" />
        <div class="wrapper">
            <div class="container">
                <div class="modal-header">
                    <h2 class="modal-title">{{ $t(`Thank you for your purchase!`) }}</h2>
                    <img src="@/assets/close.svg" @click="handleCloseModal" class="close" />
                </div>
                <p class="discount-message">
                    As a complimentary gift you have <span class="highlight">{{ discountDuration }}min</span> of
                    <span class="highlight">{{ parseInt(discountPercentage) }}%</span> discount to catch more skins into
                    your
                    account
                </p>
                <div class="product-list-section">
                    <div class="list products product-cards" v-if="products && products.length">
                        <div class="item" v-for="(item, i) in products" :key="i">
                            <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem"
                                :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currencySymbol"
                                @addToCart="addToCart" />
                        </div>
                    </div>
                </div>
                <button class="market-button button" @click="goToMarket">{{ $t('Go to the market') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import ProductCard from './ProductCard.vue';

export default {
    name: 'DiscountModal',
    components: {
        ProductCard,
    },
    props: {
        isAuth: {
            type: Boolean,
            required: true
        },
        currencySymbol: {
            type: String,
            required: true
        },
        currencyCode: {
            type: String,
            required: true
        },
        addToCartChosenItem: {
            type: [Number, String],
            required: true
        },
    },
    data() {
        return {
            discountDuration: 0,
            discountPercentage: 0,
            products: [],
        };
    },
    watch: {
        isAuth(newVal) {
            if (newVal) {
                this.fetchDiscountData();
            }
        }
    },
    methods: {
        addToCart(item, id, item_type) {
            this.$emit("addToCart", item, id, item_type);
        },
        async fetchDiscountData() {
            if (this.isAuth) {
                try {
                    const response = await this.$http.get(process.env.VUE_APP_API + 'post-purchase-discount?currency=' + this.currencyCode);
                    const data = response.data;
                    if (data.status == 'ERROR') {
                        this.handleCloseModal();
                    } else {
                        this.$emit('openDiscountModal');
                        this.discountDuration = data.discountDuration;
                        this.discountPercentage = data.discountPercentage;
                        this.products = data.randomItems;
                    }
                } catch (error) {
                    console.error('Error fetching discount data:', error);
                }
            }
        },
        goToProduct(item) {
            this.$emit('goToProduct', item);
            this.$emit('closeDiscountModal');
        },
        handleCloseModal() {
            this.$emit('closeDiscountModal');
        },
        goToMarket() {
            this.$emit('closeDiscountModal');
            this.$router.push('/products/all');
        },
    },
};
</script>

<style lang="scss" scoped>
.modal.discount-modal {
    font-family: 'Nunito', sans-serif;
    color: #fff;

    ::v-deep(.item-wrapper) {
        height: 264px;

        .preview {
            width: 124px;
            height: 80px;
            // margin: 0 auto;
            // order: 2;
            // padding-top: 72%;
        }

        .price {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #EC7633;
            font-size: 14px;
            line-height: 33px;
            order: 1;
            position: relative;
            top: 0;

        }

        .discount {
            position: relative;
            top: 0;
            max-width: 67px;

            +span {
                top: 0;
            }
        }

        .desc {
        }
    }

    .product-list-section {
        overflow-y: auto;

        .products .item {
            width: 100%;
        }
    }

    .wrapper {
        // max-width: 790px;
        max-width: 1030px;

    }

    .container {
        max-width: 790px;
        max-height: 90vh;
        padding: 32px;
        background-color: #2B2D32;
        box-shadow: 0px 8px 8px 0px #0000001F;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
}

.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    line-height: 46px;
}

.modal-title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.discount-message {
    text-align: center;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;

}

.highlight {
    // background-color: #6D82F2;
    color: #6D82F2;
    // padding: 2px 4px;
    // border-radius: 4px;
}

.list.product-cards {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    grid-template-columns: repeat(auto-fill, minmax(228px, 1fr));
    gap: 24px 18px;
    justify-content: center;
    margin-bottom: 24px;
    padding: 0;
}

.product-cards>* {
    max-width: 228px;
    width: 100%;
}

.market-button {
    width: 100%;
    padding: 13px;
    border-radius: 16px;
    line-height: 26px;

}

@media (max-width: 768px) {
    .modal .button.market-button {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .product-cards {
        grid-template-columns: 1fr;
    }
}
</style>