// src/utils/phoneUtils.js
import { getCountries } from 'libphonenumber-js';

/**
 * Returns a list of supported country ISO codes from the libphonenumber-js library.
 * This is used to filter the countries provided by the API to ensure compatibility
 * with the vue-phone-number-input component, avoiding errors due to unsupported countries.
 */
export function getSupportedCountryCodes() {
    return getCountries();
}
