<template>
  <div class="features-section">
    <ul class="list">
      <li class="item">
        <div class="icon"></div>
        <div class="title">100% {{ $t("Secure") }}</div>
        <div class="desc small">{{ $t("We use a variety of security measures to protect user accounts and transactions") }}.</div>
      </li>
      <li class="item">
        <div class="icon"></div>
        <div class="title">{{ $t("Wide selection") }}</div>
        <div class="desc small">{{ $t("We offer a wide selection of skins, including rare and exclusive items") }}.</div>
      </li>
      <li class="item">
        <div class="icon"></div>
        <div class="title">{{ $t("Best Prices") }}</div>
        <div class="desc small">{{ $t("We offer the best prices on Rust skins and items. If you find a lower price on another website, we'll match one") }}.</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: [],
  data: function () {
    return {};
  },
  methods: {},
};
</script>
