<template>
  <main
    class="main profile-page page-inside"
    v-if="$parent.isAuth"
  >
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="profile-wrapper">
            <div class="profile-nav">
              <div class="nav__item">
                <router-link to="/profile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.83"
                    height="18.354"
                    viewBox="0 0 13.83 18.354"
                  >
                    <path
                      id="user"
                      d="M102.751,11.395A4.034,4.034,0,1,1,98.717,7.36a4.034,4.034,0,0,1,4.034,4.035Zm-4.034,5.228a8.22,8.22,0,0,0-6.073,2.67,3.209,3.209,0,0,0,.686,4.9,10.3,10.3,0,0,0,10.775,0,3.209,3.209,0,0,0,.686-4.9A8.22,8.22,0,0,0,98.717,16.622Z"
                      transform="translate(-91.802 -7.36)"
                      fill="#9898a9"
                    />
                  </svg>
                  {{ $t("My profile") }}
                </router-link>
              </div>
              <div class="nav__item">
                <router-link to="/sell-offers">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.104"
                    height="17.143"
                    viewBox="0 0 16.104 17.143"
                  >
                    <g
                      id="history"
                      transform="translate(-15.52)"
                    >
                      <path
                        id="Контур_241"
                        data-name="Контур 241"
                        d="M23.323,13.556A4.714,4.714,0,0,1,30.6,9.6c.005-.349.008-.722.008-1.124a42.413,42.413,0,0,0-.209-4.695h.02a4,4,0,0,0-2.6-3.343L27.7.4a4,4,0,0,0-.856-.186l-.028,0A22.576,22.576,0,0,0,23.182,0a25.47,25.47,0,0,0-3.835.207l-.028,0a3.99,3.99,0,0,0-.9.2V.4A4,4,0,0,0,15.73,3.77a42.438,42.438,0,0,0-.21,4.7,42.438,42.438,0,0,0,.21,4.7A4,4,0,0,0,19.3,16.744l.028,0a22.3,22.3,0,0,0,3.635.207c.777,0,1.339-.01,1.781-.028a4.7,4.7,0,0,1-1.419-3.37Zm-3.917-9.1h7.309a.8.8,0,1,1,0,1.6H19.408a.8.8,0,0,1,0-1.6Zm0,5.008a.8.8,0,1,1,0-1.6h4.169a.8.8,0,1,1,0,1.6Z"
                        transform="translate(0 0)"
                        fill="#9898a9"
                      />
                      <path
                        id="Контур_242"
                        data-name="Контур 242"
                        d="M285.807,297.74a3.587,3.587,0,1,0,3.587,3.587A3.587,3.587,0,0,0,285.807,297.74Zm1.422,4.112h-1.493a.427.427,0,0,1-.427-.427v-2.294a.427.427,0,1,1,.854,0V301h1.066a.427.427,0,1,1,0,.854Z"
                        transform="translate(-257.77 -287.771)"
                        fill="#9898a9"
                      />
                    </g>
                  </svg>
                  {{ $t("Order history") }}
                </router-link>
              </div>
              <div class="nav__item">
                <a @click="$parent.logout">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="exit"
                      d="M5.7,2A3.2,3.2,0,0,0,2.5,5.2v4h8.469L9.134,7.366a.8.8,0,0,1,1.131-1.131l3.2,3.2.006.006A.8.8,0,0,1,13.7,10v0a.8.8,0,0,1-.229.558l-.006.006-3.2,3.2a.8.8,0,0,1-1.131-1.131L10.969,10.8H2.5v4A3.2,3.2,0,0,0,5.7,18h9.6a3.2,3.2,0,0,0,3.2-3.2V5.2A3.2,3.2,0,0,0,15.3,2Z"
                      transform="translate(-2.5 -2)"
                      fill="#9898a9"
                    />
                  </svg>
                  <span>{{ $t("LogOut") }}</span>
                </a>
              </div>
            </div>
            <div class="profile-form">
              <div class="profile-form-wrapper">
                <div class="profile-form-wrapper">
                  <div class="title small">{{ $t("Personal Information") }}</div>
                  <div>
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("First name") }}:</div>
                      <input
                        type="text"
                        :placeholder="$t('First name')"
                        v-model="name"
                      />
                    </div>
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("E-mail") }}:</div>
                      <input
                        type="email"
                        :placeholder="$t('E-mail')"
                        v-model="email"
                      />
                    </div>
                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Last name") }}:</div>
                      <input
                        type="text"
                        :placeholder="$t('Last name')"
                        v-model="surname"
                      />
                    </div>
                    <div
                      class="input-container"
                      v-if="countryOptionsIso && countryOptionsIso.length"
                    >
                      <div class="desc"><span class="red">*</span> {{ $t("Phone") }}:</div>
                      <VuePhoneNumberInput
                        v-model="phone"
                        :only-countries="countryOptionsIso"
                        :default-country-code="convertedShortName"
                        @update="phoneDataUpdate($event)"
                        :translations="{ phoneNumberLabel: `*${$t('Phone')}` }"
                      />
                    </div>

                    <div class="input-container country-select-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Country") }}:</div>
                      <div class="select-wrapper">
                        <select
                          v-model="country"
                          :class="{ 'empty': !country }"
                        >
                          <option
                            value=""
                            disabled
                            selected
                          >{{ $t("Country") }}</option>
                          <option
                            v-for="option in countryOptions"
                            :key="option.id"
                            :value="option.id"
                          >
                            {{ option.title }}
                          </option>
                        </select>
                        <span
                          v-if="country"
                          class="clear-icon"
                          @click="clearCountry"
                        >&times;</span>
                      </div>
                    </div>

                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("City") }}:</div>
                      <input
                        type="text"
                        :placeholder="$t('City')"
                        v-model="city"
                      />
                    </div>

                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Address") }}:</div>
                      <input
                        type="text"
                        :placeholder="$t('Address')"
                        v-model="address"
                      />
                    </div>

                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Post Code") }}:</div>
                      <input
                        type="text"
                        :placeholder="$t('Post Code')"
                        v-model="zip"
                      />
                      <!-- <transition name="fade">
                        <div class="error-message" v-if="errors.zip">{{ errors['zip'][0] }}</div>
                      </transition> -->
                    </div>

                    <div class="input-container">
                      <div class="desc"><span class="red">*</span> {{ $t("Steam ID") }}:</div>
                      <input
                        type="text"
                        :placeholder="$t('Steam ID')"
                        v-model="steamId"
                      />
                    </div>
                  </div>
                  <button
                    class="button dark"
                    @click="updateProfileData"
                  >
                    <span>{{ $t("Save") }}</span>
                  </button>
                  <transition name="fade">
                    <div
                      class="desc red"
                      v-if="profileUpdateErrorMessage"
                    >{{ profileUpdateErrorMessage }}</div>
                  </transition>
                  <transition name="fade">
                    <div
                      class="desc green"
                      v-if="profileUpdateSuccessMessage"
                    >{{ profileUpdateSuccessMessage }}</div>
                  </transition>
                </div>
                <div class="profile-form-wrapper">
                  <div class="title small">{{ $t("Change Password") }}</div>
                  <div class="input-container">
                    <div class="desc"><span class="red">*</span> {{ $t("Current password") }}:</div>
                    <input
                      type="password"
                      placeholder="Current password"
                      v-model="curPass"
                    />
                  </div>
                  <div class="input-container long">
                    <div class="desc"><span class="red">*</span> {{ $t("New password") }}:</div>
                    <input
                      type="password"
                      placeholder="New password"
                      v-model="newPass"
                    />
                  </div>
                  <div class="input-container long">
                    <div class="desc"><span class="red">*</span> {{ $t("Confirm password") }}:</div>
                    <input
                      type="password"
                      placeholder="Confirm password"
                      v-model="repeatPass"
                    />
                  </div>
                  <div class="pass-rules">
                    <div class="desc bold">{{ $t("Password must contain") }}:</div>
                    <div class="desc">- {{ $t(`At least 6 characters, 1 upper case letter (A-Z), 1 lower case letter (a
                      - z), 1 number (0-9)`) }}</div>
                  </div>
                  <button
                    class="button dark"
                    @click="updatePassword"
                  >
                    <span>{{ $t("Save") }}</span>
                  </button>
                  <transition name="fade">
                    <div
                      class="desc red"
                      v-if="passUpdateErrorMessaage"
                    >{{ passUpdateErrorMessaage }}</div>
                  </transition>
                  <transition name="fade">
                    <div
                      class="desc green"
                      v-if="passUpdateSuccessMessaage"
                    >{{ passUpdateSuccessMessaage }}</div>
                  </transition>
                </div>

                <div class="profile-form-wrapper">
                  <div class="title small">{{ $t("Referral code") }}</div>
                  <div class="input-container">
                    <div class="desc"><span class="red">*</span> {{ $t("Referral code") }}:</div>
                    <input
                      type="text"
                      placeholder="Referral code"
                      v-model="referralCode"
                    />
                  </div>
                  <button
                    class="button dark"
                    @click="updateReferralCode"
                  >
                    <span>{{ $t("Save") }}</span>
                  </button>
                  <transition name="fade">
                    <div
                      class="desc red"
                      v-if="referralUpdateErrorMessage"
                    >{{ referralUpdateErrorMessage }}</div>
                  </transition>
                  <transition name="fade">
                    <div
                      class="desc green"
                      v-if="referralUpdateSuccessMessage"
                    >{{ referralUpdateSuccessMessage }}</div>
                  </transition>
                </div>
              </div>
            </div>
            <!-- <div class="profile-table" v-if="$parent.orderHistory">
              <div class="profile-table-wrapper profile-form-wrapper">
                <div class="title small">Your Orders</div>
                <div class="table-container">
                  <table>
                    <thead>
                      <tr>
                        <td>ID</td>
                        <td>Date/time</td>
                        <td>Skin name</td>
                        <td>Total</td>
                        <td>Status</td>
                        <td>Details</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,i) in $parent.orderHistory" :key="i">
                        <td>{{item.id}}</td>
                        <td>{{$parent.formatDate(item.created_at)}}</td>
                        <td>{{item.title}}</td>
                        <td>{{item.sum}} {{$parent.currency}}</td>
                        <td :class="[{'green': item.status == 'Accepted'}, {'white': item.status == 'Processing'}]">{{item.status}}</td>
                        <td>
                          <a class="offer-modal-open" @click="$emit('openOfferDetailsModal', item)">View</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <ConfirmationModal
      :visible="showDisclaimer"
      :title="$t('Warning')"
      :message="disclaimerMessage"
      :confirmText="$t('Proceed')"
      :cancelText="$t('Cancel')"
      @confirm="confirmReferralCodeUpdate"
      @close="cancelReferralCodeUpdate"
    />
  </main>
</template>
<script>

import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapActions, mapGetters } from 'vuex';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);
import ConfirmationModal from '@/components/ConfirmationModal.vue';

export default {
  name: 'Profile',
  props: [],
  components: {
    VuePhoneNumberInput,
    ConfirmationModal,
  },
  data: function () {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessage: '',
      profileUpdateErrorMessage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      phoneFull: "",
      convertedShortName: '',
      countryCode: '',
      countryCodeList: [],
      profilePhone: '',
      address: '',
      city: '',
      zip: '',
      country: '',
      steamId: '',
      errorTimeoutId: null,

      referralCode: '',
      referralUpdateErrorMessage: '',
      referralUpdateSuccessMessage: '',
      showDisclaimer: false,
      disclaimerMessage: '',
      currentDiscount: 0,
      newDiscount: 0,
    }
  },
  computed: {
    ...mapGetters('country', ['countryOptions', 'countryOptionsIso']),

  },
  mounted() {
    this.getProfile();
    this.fetchCountries();
  },
  methods: {
    ...mapActions('country', ['getCountries']),
    clearCountry() {
      this.country = '';
    },
    async fetchCountries() {
      try {
        await this.getCountries();
      } catch (error) {
        if (error.status === 401) {
          this.$parent.openSignInModal();
        } else {
          console.error(error);
        }
      }
    },
    getProfile() {
      let self = this;
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          if (res.status == 200) {
            self.email = res.data.email;
            self.name = res.data.name;
            self.surname = res.data.surname;
            self.phone = res.data.phone.replace(/^[^\s]*\s/, '');
            self.profilePhone = res.data.phone;
            self.address = res.data.address;
            self.city = res.data.city;
            self.zip = res.data.zip;
            self.country = res.data.country;
            self.steamId = res.data.steamId;
            self.referralCode = res.data.referral_code;
            self.getAllCountryCodes();
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    getAllCountryCodes() {
      this.$http.get(process.env.VUE_APP_API + 'phone-codes')
        .then((res) => {
          if (res.data.status == "OK") {
            this.countryCodeList = res.data.payload;
            this.countryCode = this.getSymbolsBeforeSpace(this.profilePhone).replace('+', '');
            this.getShortNameByPhoneCode(this.countryCode, this.countryCodeList);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        })
    },
    getShortNameByPhoneCode(phoneCode, codeList) {
      for (let i = 0; i < codeList.length; i++) {
        const countryCode = Object.keys(codeList[i])[0];
        const codeValue = codeList[i][countryCode].code
        if (codeValue == phoneCode) {
          this.convertedShortName = countryCode;
        }
      }
    },
    getSymbolsBeforeSpace(inputString) {
      const regex = /^[^\s]+/;
      const match = inputString.match(regex);

      if (match) {
        return match[0];
      } else {
        return ""; // Return an empty string if no symbols are found
      }
    },
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational || e.phoneNumber;
    },
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phoneFull,
        "surname": this.surname,
        "address": this.address,
        "city": this.city,
        "zip": this.zip,
        "country_id": this.country,
        "steam_id": this.steamId,
      };
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
        .then((res) => {
          if (res.data.status === "OK") {
            this.profileUpdateErrorMessage = '';
            this.profileUpdateSuccessMessage = 'Success';
            setTimeout(() => {
              this.profileUpdateSuccessMessage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }

          this.profileUpdateErrorMessage = res.response.data.message;
          this.profileUpdateSuccessMessage = '';
          // Clear the previous timeout if it exists
          if (this.errorTimeoutId) {
            clearTimeout(this.errorTimeoutId);
          }

          // Set a new timeout
          this.errorTimeoutId = setTimeout(() => {
            this.profileUpdateErrorMessage = '';
          }, 3000);
        });
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
        .then((res) => {
          if (res.data.status == "OK") {
            this.passUpdateSuccessMessaage = 'Success';
            this.curPass = '',
              this.newPass = '',
              this.repeatPass = '';
            setTimeout(() => {
              this.passUpdateSuccessMessaage = '';
            }, 3000);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.passUpdateErrorMessaage = res.response.data.message;
          setTimeout(() => {
            this.passUpdateErrorMessaage = '';
          }, 3000);
        })
    },
    updateReferralCode() {
      this.$http.put(process.env.VUE_APP_API + 'user/referral-code', { referral_code: this.referralCode, confirmed: false })
        .then((res) => {
          if (res.data.status === "OK") {
            this.handleSuccessfulUpdate(res.data.message);
          } else if (res.data.status === "WARNING") {
            this.showWarningDisclaimer(res.data);
          }
        })
        .catch(this.handleUpdateError);
    },
    confirmReferralCodeUpdate() {
      this.$http.put(process.env.VUE_APP_API + 'user/referral-code', {
        referral_code: this.referralCode,
        confirmed: true
      })
        .then((res) => {
          if (res.data.status === "OK") {
            this.handleSuccessfulUpdate(res.data.message);
          }
        })
        .catch(this.handleUpdateError);
      this.showDisclaimer = false;
    },
    cancelReferralCodeUpdate() {
      this.showDisclaimer = false;
    },
    handleSuccessfulUpdate(message) {
      this.referralUpdateErrorMessage = '';
      this.referralUpdateSuccessMessage = message;
      setTimeout(() => {
        this.referralUpdateSuccessMessage = '';
      }, 3000);
    },
    showWarningDisclaimer(data) {
      this.showDisclaimer = true;
      this.disclaimerMessage = `${data.message}<br><br>Current discount: ${data.current_discount_percentage}%<br>New discount: ${data.new_discount_percentage}%`;
      this.currentDiscount = data.current_discount_percentage;
      this.newDiscount = data.new_discount_percentage;
    },
    handleUpdateError(error) {
      if (error.response.status === 401) {
        this.$parent.openSignInModal();
      }
      this.referralUpdateErrorMessage = error.response.data.message;
      this.referralUpdateSuccessMessage = '';
      setTimeout(() => {
        this.referralUpdateErrorMessage = '';
      }, 3000);
    },
  }
}
</script>