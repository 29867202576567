<template>
  <div class="modal order-modal">
    <div
      class="overlay"
      @click="$parent.closeOrderModal"
    ></div>
    <div class="wrapper">
      <div class="container">
        <img
          src="./../assets/close.svg"
          @click="$parent.closeOrderModal"
          class="close"
        />
        <div class="title small">{{ $t("Payment Method") }}</div>
        <div class="button-list-container">

          <div
            v-if="isLoading"
            class="spinner-wrapper"
          >
            <div class="dual-ring"></div>
          </div>

          <div
            class="button-list"
            v-else-if="paymentMethods && paymentMethods.length"
          >
            <button
              v-for="(item, i) in paymentMethods"
              class="img-btn"
              @click="submit(item.code)"
              :key="i"
            >
              <div class="image-wrapper">
                <img
                  :src="item.image"
                  class="img"
                />
              </div>
              <div class="payment-method-title">{{ $t(item.title) }}</div>
            </button>
          </div>

          <div
            class="desc"
            v-else-if="!isLoading && !paymentMethods.length && !error"
          >
            {{ $t("There are no payment methods available") }}
          </div>

          <transition name="fade">
            <div
              class="desc red"
              v-if="error || this.$parent.error"
            >{{ error || this.$parent.error }}</div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'OrderModal',
  props: ['orderData', 'appDomain', 'orderModalIsVisible'],
  data: function () {
    return {
      isLoading: false,
      availablePaymentMethods: [],
      error: '',
    }
  },
  computed: {
    ...mapGetters('app', ['currency']),
    paymentMethods() {
      return this.availablePaymentMethods;
    },
  },
  watch: {
    orderModalIsVisible() {
      if (this.orderModalIsVisible) {
        this.fetchPaymentMethods(this.orderData.country, this.currency);
      }
    }
  },
  methods: {
    async fetchPaymentMethods(countryId, currencyCode) {
      this.isLoading = true;
      this.error = '';
      this.availablePaymentMethods = [];
      try {
        const response = await this.$http.post(process.env.VUE_APP_API + 'orders/payment-methods', {
          country: countryId,
          currency: currencyCode,
        });
        if (response.data.status === 'OK') {
          this.availablePaymentMethods = response.data.payload;
        } else {
          this.error = response.data.message;
        }
      } catch (error) {
        console.error('Error fetching payment methods:', error);
        this.error = 'Error fetching payment methods';
      } finally {
        this.isLoading = false;
      }
    },
    submit(type) {
      this.$emit('orderSubmit', this.orderData, type)
    },
  },
  mounted() {
    if (this.orderModalIsVisible) {
      this.fetchPaymentMethods(this.orderData.country, this.currency);
    }
  }
}
</script>
<style scoped>
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}


.dual-ring,
.dual-ring:after {
  box-sizing: border-box;
}

.dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid white;
  border-color: white transparent white transparent;
  animation: dual-ring 1.2s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>