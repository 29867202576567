import axios from "axios";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  timeout: 5000, // default timeout
});

instance.interceptors.request.use(
  function (config) {
    // Access the currency getter from Vuex store
    const currency = store.getters["app/currency"] || "EUR";

    const lang_id = store.getters["app/currentLanguage"];

    if (config.method === "get" && !config.url.includes("orders/history")) {
      config.params = {
        ...config.params,
        lang_id,
        currency,
      };
    } else if (
      ["post", "put", "patch", "POST", "PUT", "PATCH"].includes(config.method)
    ) {
      if (config.data instanceof FormData) {
        config.data.append("currency", currency);
      } else {
        config.data = {
          ...config.data,
          currency,
          lang_id,
        };
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
